









import PdfViewer from "@/components/files/PdfViewer.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { renderTemplate } from "@/lib/utility/renderTemplate";
import DocumentTemplateMixin from "@/mixins/DocumentTemplateMixin.vue";
import { MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
const pdfMeUi = () => import("@pdfme/ui");

@Component({
  components: { LayoutSimple, PdfViewer },
  filters: {}
})
export default class DocumentTemplateDetailForm extends mixins(DocumentTemplateMixin) {
  form: any = null;

  async mounted() {
    const pdfMe = await pdfMeUi();
    const domContainer = document.getElementById("container");
    if (!domContainer) {
      throw Error("error no dom container");
    }

    const template = {
      basePdf: pdfMe.BLANK_PDF,
      schemas: []
    };

    this.form = new pdfMe.Form({ domContainer, template, inputs: [{}] });
  }

  get user() {
    return UserModule.user;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get report() {
    return PartnerModule.report;
  }

  get inputs() {
    const inputs = this.schemas.map(schema => {
      return { ...schema };
    });

    for (const input of inputs) {
      for (const key of Object.keys(input)) {
        const token = this.getTokenFromKey(key);

        input[token] = renderTemplate(token, {
          user: this.user as MrfiktivUserViewmodelGen,
          partner: this.partner
        });
      }
    }
    return inputs;
  }

  setTemplate() {
    const template = {
      basePdf: DocumentModule.maps.ids.get(this.$route.params.documentId)[0].url,
      schemas: this.schemas
    };

    this.form?.updateTemplate(template);
    this.form?.setInputs(this.inputs);
  }
}
